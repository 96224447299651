.arrow-info {
    width: 320px;
    height: 60px;
    background-color: #EEDD82;
    border-radius: 8px 0 0 8px;
    text-transform: uppercase;
    color: #1A2947;
    display: flex;
    align-items: center;
    animation: move 2s ease infinite alternate, nudge 2s linear infinite alternate;
}

.arrow-info:before {
    display: inline-block;
    content: "";
    border-style: solid;
    border-width: 30px 0 30px 34px;
    border-color: transparent transparent transparent #EEDD82;
    position: relative;
    left: 320px;
}

@keyframes move {
    0% {
        position: relative;
        left: 0;
    }
    100% {
        position: relative;
        left: 30px;
    }
}

.arrow-info span {
    width: 100%;
    text-align: center;
    margin-left: -40px;
    font-size: 20px;
    font-weight: 500;
}